<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8 mb-5">
          <div id="hms"></div>
      </div>
    </div>
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          Empresas:
        </span>
        <v-autocomplete
          v-model="filters.companies"
          :items="companyItems"
          multiple
          placeholder="Todas las empresas"
          class="range-field"
        />
      </div>
    </div>
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          Tipo:
        </span>
        <v-select
          v-model="filters.type"
          :items="typeItems"
          class="range-field"
          style="width: 120px"
        />
      </div>
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          Fecha de expiracion:
        </span>
        <v-menu
          v-model="menuExpiredDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.expiredDate"
              label=""
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="filters.expiredDate"
            :min="minExpiredDate"
            @input="menuExpiredDate = false"
          />
        </v-menu>
      </div>
      <div v-if="filters.expiredDate" class="d-flex align-center mr-8">
        <span class="mr-2">
          D.E.M:
        </span>
        <v-text-field
          v-model="filters.strikePercents"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <div v-if="filters.strikePercents" class="d-flex align-center mr-8">
        <span class="mr-2">
          D.E.S:
        </span>
        <v-radio-group v-model="filters.strikeInterval" row>
          <!-- <v-radio label="off" :value="null" /> -->
          <!-- <v-radio label="1$" :value="1" /> -->
          <v-radio label="5$" :value="5" />
          <v-radio label="10$" :value="10" />
          <v-radio label="20$" :value="20" />
        </v-radio-group>
      </div>
      <div v-if="filters.strikeInterval" class="d-flex align-center mr-8">
        <span class="mr-2">
          G.N:
        </span>
        <v-text-field
          v-model="filters.gn"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          style="width: 50px"
        />
      </div>
      <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          Open Interest:
        </span>
        <v-radio-group v-model="filters.openInterest" row>
          <v-radio label="50" :value="50" />
          <v-radio label="100" :value="100" />
          <v-radio label="200" :value="200" />
        </v-radio-group>
      </div>
      <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          E-Date:
        </span>
        <v-radio-group v-model="filters.earmingDate" row>
          <v-radio label="Si" :value="true" />
          <v-radio label="No" :value="false" />
        </v-radio-group>
      </div>

      <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          SMA50:
        </span>
        <v-radio-group v-model="filters.sma50" row>
          <v-radio label="Si" :value="true" />
          <v-radio label="No" :value="false" />
        </v-radio-group>
      </div>

      <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          RSI14:
        </span>
        <v-radio-group v-model="filters.rsi14" row>
          <v-radio label="Si" :value="true"/>
          <v-radio label="No" :value="false" />
        </v-radio-group>
      </div>

      <!-- <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          RSI14 BANDA:
        </span>
        <v-radio-group v-model="filters.rsi14Banda" row>
          <v-radio label="Si" :value="true"/>
          <v-radio label="No" :value="false" />
        </v-radio-group>
      </div> -->

      <div v-if="filters.type && filters.gn" class="d-flex align-center mr-8">
        <span class="mr-2">
          MACD:
        </span>
        <v-radio-group v-model="filters.macd" row>
          <v-radio label="Si" :value="true"/>
          <v-radio label="No" :value="false" />
        </v-radio-group>
      </div>

      <!-- <div v-if="filters.rsi14" class="d-flex align-center mr-8">
        <span class="mr-2">
          Monto:
        </span>
        <v-text-field
          v-model="filters.rsi14Mount"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div> -->

      <v-btn
        v-if="filters.type && filters.openInterest"
        depressed
        color="primary"
        @click="applyFilter"
      >
        Filtrar
      </v-btn>
    </div>
    <div class="modal">
      <span class="tt" v-if="firstCalc && resultData.length">Numero de Alternativas: {{ resultData.length / 4 }}</span>
      <v-dialog v-model="dialog" width="500" v-if="tickerErrors.length">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
            Empresas no encontradas
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>

          <v-card-text class="companies-error">
            <ul class="d-flex">
              <li v-for="item in tickerErrors" :key="item">
                {{ item }}
              </li>
            </ul>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <template v-if="modalRSI.active && modalRSIData">
      <v-dialog v-model="modalRSI.active" width="800" @click:outside="closeRSIModal">
        <v-card>
          <!-- <v-card-title class="text-h5 grey lighten-2">
            <span v-if="modalRSI.type.toLowerCase() === 'rsi'">Ultimos RSI 14</span>
          </v-card-title> -->

          <!-- :item-class="classes" -->
          <v-card-text class="table-rsi">
            <div class="item">
              <h3>Ultimos 20 MACD ( {{ modalRSIData.macd.ticker }} )</h3>
              <h4>Promedio: {{ modalRSIData.macd.average }}</h4>
              <v-data-table
                :headers="modalRSIData.macd.headers"
                :items="modalRSIData.macd.data"
                :items-per-page="5000"
                disable-sort
                hide-default-footer
                :footer-props="{
                  'disable-items-per-page': true,
                  'items-per-page-text': '',
                }"
              />
            </div>
            <div class="item">
              <h3>Ultimos 14 RSI14 ( {{ modalRSIData.rsi14.ticker }} )</h3>
              <h4>Promedio: {{ modalRSIData.rsi14.average }}</h4>
              <v-data-table
                :headers="modalRSIData.rsi14.headers"
                :items="modalRSIData.rsi14.data"
                :items-per-page="5000"
                disable-sort
                hide-default-footer
                :footer-props="{
                  'disable-items-per-page': true,
                  'items-per-page-text': '',
                }"
              />
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeRSIModal">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <material-card icon="mdi-clipboard-text" icon-small color="accent">
      <v-data-table
        :headers="table.headers"
        :item-class="classes"
        :items="resultData"
        :items-per-page="5000"
        disable-sort
        @click:row="handleClick"
        :footer-props="{
          'disable-items-per-page': true,
          'items-per-page-text': '',
        }"
        :loading="loadTable"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.ticker`]="{ item }">
          <a v-if="item.ticker" :href="`https://www.cnbc.com/quotes/${item.ticker}`" target="_blank">
            {{ item.ticker }}
          </a>
        </template>
      </v-data-table>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import companies from '../companies'
  const RUTA_SERVIDOR = 'https://www.financetradebio.com'
  export default {
    name: 'RegularTablesView',
    data() {
      return {
        dialog: false,
        table: {
          headers: [
            { text: 'Symbol', value: 'ticker' },
            {
              text: 'Stock Price',
              value: 'stockPrice',
              // cellClass: 'strike-column',
            },
            { text: 'Call Bid Price', value: 'callBidPrice' },
            { text: 'Call Ask Price', value: 'callAskPrice' },
            { text: 'Call Open Interest', value: 'callOpenInterest' },
            {
              text: 'Strike',
              value: 'strike',
              // cellClass: 'strike-column'
            },
            { text: 'Put Bid Price', value: 'putBidPrice' },
            { text: 'Put Ask Price', value: 'putAskPrice' },
            { text: 'Put Open Interest', value: 'putOpenInterest' },
            { text: 'Expir Date', value: 'expirDate' },
          ],
        },
        typeItems: [
          {
            value: null,
            text: 'Call y put',
          },
          {
            value: 'call',
            text: 'Call',
          },
          {
            value: 'put',
            text: 'Put',
          },
        ],
        earmingData: [],
        responseData: [],
        loadTable: true,
        loading: false,
        menuExpiredDate: false,
        companies,
        filters: {
          expiredDate: '',
          strikePercents: 0,
          companies: [],
          strikeInterval: null,
          type: null,
          gn: 0,
          openInterest: 0,
          earmingDate: false,
          sma50: false,
          rsi14: false,
          // rsi14Banda: false,
          macd: false,
          rsi14Mount: 0,
        },
        companyItems: [],
        data: [],
        tickerErrors: [],
        firstCalc: false,
        smaRsiData: {},
        rsiData: {},
        modalRSI: {
          active: false,
          type: null,
          ticker: null,
        },
        modalRSIData: null,
        s: 0,
        m: 0,
      }
    },
    computed: {
      minExpiredDate() {
        const now = new Date()
        const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
        const month =
          now.getMonth() < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
        return `${now.getFullYear()}-${month}-${day}`
      },
      resultData() {
        if (this.filters.companies.length) {
          return this.data.filter(({ ticker }) => {
            return this.filters.companies.includes(ticker)
          })
        }
        return this.data
      },
    },
    async mounted() {
      document.getElementById("hms").innerHTML="00:00";
      this.setHeaders()
      const a = await this.setEarmingData()
      const data = await this.getData()
    },
    methods: {
      classes(e) {
        var result = ''
        if (e.par) {
          result = result += ' strike-column'
        }
        if (e.gn) {
          result = result += ' gn'
        }
        return result
      },
      async applyFilter() {
        this.loading = true
        this.reiniciarCronometro()
        const control = setInterval(this.escribir,1000);
        const _expiredDate = new Date(this.filters.expiredDate)
        const type = this.filters.type
        const gn = this.filters.gn
        if (!type) return this.data
        this.setHeaders()
        var data = [...this.responseData].map(el => ({
          ...el,
          putSum: ((el.putBidPrice + el.putAskPrice) / 2).toFixed(2),
          callSum: ((el.callBidPrice + el.callAskPrice) / 2).toFixed(2),
        }))
        if (this.filters.expiredDate) {
          data = data.filter(({ expirDate }) => {
            const _expirDate = new Date(expirDate)
            return _expiredDate.getTime() >= _expirDate.getTime()
          })
        }

        if (this.filters.earmingDate) {
          data = data.filter(({ ticker }) => {
            const earmingDate = this.findEarmingDate(ticker)
            const _earningDate = new Date(earmingDate)
            return (
              !earmingDate || _earningDate.getTime() >= _expiredDate.getTime()
            )
          })
        }

        if (this.filters.openInterest) {
          data = data.filter(element => {
            return element[`${type}OpenInterest`] >= this.filters.openInterest
          })
        }
        data = data.filter(element => {
          const bidMayor =
            element[`${type}BidPrice`] > element[`${type}AskPrice`]
          const percent =
            (bidMayor
              ? element[`${type}BidPrice`]
              : element[`${type}AskPrice`]) * 0.7
          if (bidMayor) {
            return element[`${type}AskPrice`] > percent
          } else {
            return element[`${type}BidPrice`] > percent
          }
        })

        var colored = false
        if (this.filters.strikePercents) {
          data = data.filter(data => {
            const start =
              data.stockPrice -
              (data.stockPrice * this.filters.strikePercents) / 100 +
              1
            return data.strike <= start
          })
          if (this.filters.type === 'put') {
            data = data.reverse()
            data = data.sort((a, b) => a.ticker.localeCompare(b.ticker))
          }
          if (this.filters.strikeInterval) {
            data = data
              .reduce((total, current, i) => {
                const strikeInterval = this.filters.strikeInterval
                const strikePercents = this.filters.strikePercents
                // const start = current.stockPrice - (current.stockPrice * strikePercents) / 100 + 1
                const last = total[total.length - 1]
                if (i === 0) {
                  total.push(current)
                } else if (last.ticker !== current.ticker) {
                  total.push(current)
                } else if (last.expirDate !== current.expirDate) {
                  total.push(current)
                } else if (
                  current.strike === last.strike + strikeInterval ||
                  current.strike === last.strike - strikeInterval
                ) {
                  total.push(current)
                }
                return total
              }, [])
              .reduce((total, current, i, arr) => {
                // Se crean los pares
                // par++
                var data2 = { ticker: current.ticker }
                var data3 = { ticker: current.ticker }
                // var data4 = { ticker: current.ticker }

                const nextItem = arr[i + 1]
                if (
                  nextItem &&
                  current.ticker === nextItem.ticker &&
                  current.expirDate === nextItem.expirDate
                ) {
                  if (
                    !current[`${type}BidPrice`] ||
                    !current[`${type}AskPrice`] ||
                    !nextItem[`${type}BidPrice`] ||
                    !nextItem[`${type}AskPrice`]
                  ) {
                    return total
                  }
                  var currentStrike = current.strike > nextItem.strike
                  data2.gn = true
                  data3.gn = true
                  // data4.gn = true
                  if (currentStrike) {
                    data2[`${type}Sum`] = (
                      current[`${type}Sum`] - nextItem[`${type}Sum`]
                    ).toFixed(2)
                  } else {
                    data2[`${type}Sum`] = (
                      nextItem[`${type}Sum`] - current[`${type}Sum`]
                    ).toFixed(2)
                  }

                  if (Number(data2[`${type}Sum`]) <= Number(this.filters.gn)) {
                    return total
                  }

                  data2.stockPrice = current.stockPrice
                  data2.sma = true
                  data3.stockPrice = current.stockPrice
                  data3.sma2 = true
                  // data4.rsi2 = true

                  total.push({ ...current })
                  total.push({ ...nextItem })
                  total.push(data2)
                  total.push(data3)
                  // total.push(data4)
                }
                return total
              }, [])
            data.forEach((el, i) => {
              if (el.sma2) {
                if (!colored) {
                  colored = true
                  el.par = true
                  data[i - 1].par = true
                  data[i - 2].par = true
                  data[i - 3].par = true
                  // data[i - 4].par = true
                } else {
                  colored = false
                  el.par = false
                  data[i - 1].par = false
                  data[i - 2].par = false
                  data[i - 3].par = false
                  // data[i - 4].par = false
                }
              }
            })
          }
        }
        this.companyItems = data.reduce((total, current) => {
          if (current.ticker) {
            const isExist = total.some(x => x.value === current.ticker)
            if (!isExist) {
              total.push({ value: current.ticker, text: current.ticker })
            }
          }
          return total
        }, [])
        this.loading = true
        const ad = await this.getAdicionalData(data)

        if (this.filters.sma50) {
          data = data.filter(element => {
            const smaDataa = this.smaRsiData[element.ticker]
            return element.stockPrice > smaDataa.sma[50]
          })
        }

        if (this.filters.rsi14) {
          await this.getRsiFilter(data)
          data = data.filter(element => {
              const smaDataaa = this.smaRsiData[element.ticker]
              return smaDataaa.rsi[14] > (this.rsiData[element.ticker].reduce((t, c) => t + Number(c.value), 0) / this.rsiData[element.ticker].length).toFixed(2)
              // return smaDataaa.rsi[14] > Number(this.filters.rsi14Mount)
          })
        }

        // if (this.filters.rsi14Banda) {
        //   await this.getRsiFilter(data)
        //   data = data.filter(element => {
        //       const smaDataaa = this.smaRsiData[element.ticker]
        //       return smaDataaa.rsi[14] >= 45 && smaDataaa.rsi[14] <= 65
        //   })
        // }

        if (this.filters.macd) {
          data = data.filter(element => {
              const smaDataMCD = this.smaRsiData[element.ticker]
              return smaDataMCD.macd.macd > smaDataMCD.macd.signal
          })
        }

        data = data.map(el => {
          // console.log(el);
          if (el.sma) {
            const smaData = this.smaRsiData[el.ticker]
            el.stockPrice = `RSI14: ${smaData.rsi[14]}`
            el.strike = `MACD HIST: ${smaData.macd.hist}`
            el[`${type}BidPrice`] = `MACD: ${smaData.macd.macd}`
            el[`${type}AskPrice`] = `MACD SIGNAL: ${smaData.macd.signal}`
            el[`${type}OpenInterest`] = `SMA50: ${smaData.sma[50]}`
            el.expirDate = `SMA200: ${smaData.sma[200]}`
          }
          if (el.sma2) {
            const smaData2 = this.smaRsiData[el.ticker]
            el.stockPrice = ''
            el[`${type}OpenInterest`] = `SMA10: ${smaData2.sma[10]}`
            el.expirDate = `SMA20: ${smaData2.sma[20]}`
          }
          // if (el.rsi2) {
          //   const rsiData2 = this.smaRsiData[el.ticker]
          //   el.ticker = 'RSI 14:'
          //   el.stockPrice = '8) ' + rsiData2.rsi[14][7]
          //   el.strike = '9) ' + rsiData2.rsi[14][8]
          //   el[`${type}BidPrice`] = '10) ' + rsiData2.rsi[14][9]
          //   el[`${type}AskPrice`] = '11) ' + rsiData2.rsi[14][10]
          //   el[`${type}Sum`] = '12) ' + rsiData2.rsi[14][11]
          //   el[`${type}OpenInterest`] = '13) ' + rsiData2.rsi[14][12]
          //   el.expirDate = '14) ' + rsiData2.rsi[14][13]
          // }
          return el
        })

        clearInterval(control);
        this.loading = false
        this.filters.companies = []
        this.data = data
        this.firstCalc = true
      },

      async getRsiFilter(data){
        const tickerData = this.getTickers(data)

        try {
          for (const ticker of tickerData) {
            const url = `https://www.alphavantage.co/query?function=RSI&symbol=${ticker}&interval=daily&time_period=14&series_type=close&apikey=Q8WT50S09ESSBW1K`
            const rsiAll = await axios.get(url).then(res => res.data)

            if (JSON.stringify(rsiAll['Technical Analysis: SMA']) !== '{}') {
              this.rsiData[ticker] = Object.keys(rsiAll['Technical Analysis: RSI'])
                .slice(0, 14)
                .map(el => ({
                  date: el,
                  value: parseFloat(
                    rsiAll['Technical Analysis: RSI'][el].RSI,
                  ).toFixed(2),
                }))
            }
          }
          return true

        } catch (error) {
          throw new Error(error)
        }

      },

      async getOne(ticker) {
        const url = 'https://api.orats.io/datav2/strikes'
        const token = 'c9ddc590-b02f-11ee-b042-97d197ca8b28'
        const fields =
          'ticker,expirDate,strike,stockPrice,callBidPrice,callAskPrice,callOpenInterest,putBidPrice,putAskPrice,putOpenInterest'
        try {
          const data = await axios
            .get(url, { params: { token, ticker, fields } })
            .then(({ data: response }) => response.data)
          return data
        } catch (error) {
          console.log(error)
          this.tickerErrors.push(ticker)
        }
      },

      async getData() {
        this.loading = true
        const control = setInterval(this.escribir,1000);
        var result = []
        var errorTickers = []
        const url = 'https://api.orats.io/datav2/strikes'
        const token = 'c9ddc590-b02f-11ee-b042-97d197ca8b28'
        const fields =
          'ticker,expirDate,strike,stockPrice,callBidPrice,callAskPrice,callOpenInterest,putBidPrice,putAskPrice,putOpenInterest'
        for (let i = 0; i < 600; i++) {
          if (!this.companies[i * 10]) {
            break
          }
          const ticker = this.companies.slice(i * 10, 10 * (i + 1))
          try {
            const data = await axios
              .get(url, { params: { token, ticker: ticker.join(','), fields } })
              .then(({ data: response }) => response.data)
            result = [...result, ...data]
          } catch (error) {
            errorTickers = errorTickers.concat(ticker)
          }
        }
        const promi = errorTickers.map(ticker => this.getOne(ticker))
        const checkValidInErrorTickers = await Promise.all(promi).then(res => {
          return res
            .filter(res => res)
            .reduce((total, c) => total.concat(c), [])
        })
        result = [...result, ...checkValidInErrorTickers]
        this.responseData = result
        this.data = result
        clearInterval(control);
        this.loadTable = false
        this.loading = false
      },
      setHeaders() {
        const type = this.filters.type
        this.table = {
          headers: [
            { text: 'Symbol', value: 'ticker' },
            {
              text: 'Stock Price',
              value: 'stockPrice',
              // cellClass: 'strike-column',
            },
            { text: 'Call Bid Price', value: 'callBidPrice' },
            { text: 'Call Ask Price', value: 'callAskPrice' },
            { text: 'Call Bid + Ask / 2', value: 'callSum' },
            { text: 'Call Open Interest', value: 'callOpenInterest' },
            {
              text: 'Strike',
              value: 'strike',
              // cellClass: 'strike-column'
            },
            { text: 'Put Bid Price', value: 'putBidPrice' },
            { text: 'Put Ask Price', value: 'putAskPrice' },
            { text: 'Put Bid + Ask / 2', value: 'putSum' },
            { text: 'Put Open Interest', value: 'putOpenInterest' },
            { text: 'Expir Date', value: 'expirDate' },
            // { text: 'par', value: 'par' },
          ].filter(e => {
            if (
              [
                'putBidPrice',
                'putAskPrice',
                'putOpenInterest',
                'callBidPrice',
                'callAskPrice',
                'callOpenInterest',
                'callSum',
                'putSum',
              ].includes(e.value)
            ) {
              if (
                type === null &&
                [
                  'putBidPrice',
                  'putAskPrice',
                  'callBidPrice',
                  'callAskPrice',
                ].includes(e.value)
              ) {
                return true
              } else if (
                type === 'put' &&
                [
                  'putBidPrice',
                  'putAskPrice',
                  'putOpenInterest',
                  'putSum',
                ].includes(e.value)
              ) {
                return true
              } else if (
                type === 'call' &&
                [
                  'callBidPrice',
                  'callAskPrice',
                  'callOpenInterest',
                  'callSum',
                ].includes(e.value)
              ) {
                return true
              }
              return false
            }
            return true
          }),
        }
      },
      async setEarmingData() {
        const earming = await axios
          .get(`${RUTA_SERVIDOR}/earning`)
          .then(res => res.data)
        this.earmingData = earming.data.map(
          ({ ticker, expiredAt: earmingDate }) => {
            return { ticker, earmingDate }
          },
        )
      },
      findEarmingDate(ticker) {
        const line = this.earmingData.find(e => e.ticker === ticker)
        if (!line) return null
        return line.earmingDate
      },
      async getAdicionalData(data) {
        const tickerData = this.getTickers(data)
        try {
          for (const ticker of tickerData) {
            const url = `${RUTA_SERVIDOR}/smaRsi/${ticker}`

            if (!this.smaRsiData[ticker]) {
              const rsiAll = await axios.get(url).then(res => res.data)

              var res50 = 0
              var res200 = 0
              var res10 = 0
              var res20 = 0
              var resRsi = 0
              var resMacdHist = 0
              var resMacd = 0
              var resMacdSignal = 0

              if (Object.keys(rsiAll.data).length > 0) {
                res50 = Number(rsiAll.data[0].sma50)
                res200 = Number(rsiAll.data[0].sma200)
                res10 = Number(rsiAll.data[0].sma10)
                res20 = Number(rsiAll.data[0].sma20)
                resRsi = Number(rsiAll.data[0].rsi14)
                resMacdHist = Number(rsiAll.data[0].macdHist)
                resMacd = Number(rsiAll.data[0].macd)
                resMacdSignal = Number(rsiAll.data[0].macdSignal)
              }

              this.smaRsiData[ticker] = {
                sma: {
                  200: res200,
                  50: res50,
                  20: res20,
                  10: res10,
                },
                rsi: {
                  14: resRsi,
                },
                macd: {
                  hist: resMacdHist,
                  macd: resMacd,
                  signal: resMacdSignal,
                },
              }
            }
          }
          return true
        } catch (error) {
          throw new Error(error)
        }
      },
      openRSIModal(ticker, type) {
        // this.modalRSI.type = type
        this.modalRSI.ticker = ticker
        this.setModalRSIData()
        this.modalRSI.active = true
      },
      closeRSIModal() {
        // this.modalRSI.type = null
        this.modalRSI.ticker = null
        this.modalRSIData = null
        this.modalRSI.active = false
      },
      handleClick(e) {
        if (e.strike && e.strike.startsWith('MACD')) {
          this.openRSIModal(e.ticker)
        }
        // console.log(e)
      },
      getTickers(data) {
        return data.reduce((total, c) => {
          if (!c.ticker || total.includes(c.ticker)) {
            return total
          } else {
            return total.concat([c.ticker])
          }
        }, [])
      },
      async setModalRSIData() {
        // if (!this.modalRSI.active) return null
        this.loading = true
        const ticker = this.modalRSI.ticker

        var allRSI = []
        var allMACD = []

        try {
          const url = `https://www.alphavantage.co/query?function=RSI&symbol=${ticker}&interval=daily&time_period=14&series_type=close&apikey=Q8WT50S09ESSBW1K`
          const url2 = `https://www.alphavantage.co/query?function=MACD&symbol=${ticker}&interval=daily&series_type=close&apikey=Q8WT50S09ESSBW1K`
          const rsiAll = await axios.get(url).then(res => res.data)
          const macdAll = await axios.get(url2).then(res => res.data)

          if (JSON.stringify(rsiAll['Technical Analysis: SMA']) !== '{}') {
            allRSI = Object.keys(rsiAll['Technical Analysis: RSI'])
              .slice(0, 14)
              .map(el => ({
                date: el,
                value: parseFloat(
                  rsiAll['Technical Analysis: RSI'][el].RSI,
                ).toFixed(2),
              }))
          }

          if (JSON.stringify(macdAll['Technical Analysis: MACD']) !== '{}') {
            allMACD = Object.keys(macdAll['Technical Analysis: MACD'])
              .slice(0, 20)
              .map(el => ({
                date: el,
                value: parseFloat(
                  macdAll['Technical Analysis: MACD'][el].MACD,
                ).toFixed(2),
              }))
          }
        } catch (error) {
          throw new Error(error)
        }

        const rsi14 = allRSI
        const macd = allMACD

        this.modalRSIData = {
          rsi14: {
            data: rsi14,
            ticker: ticker,
            average: (
              rsi14.reduce((t, c) => t + Number(c.value), 0) / rsi14.length
            ).toFixed(2),
            headers: [
              { text: 'Fecha', value: 'date' },
              { text: 'RSI14', value: 'value' },
            ],
          },
          macd: {
            data: macd,
            ticker: ticker,
            average: (
              macd.reduce((t, c) => t + Number(c.value), 0) / macd.length
            ).toFixed(2),
            headers: [
              { text: 'Fecha', value: 'date' },
              { text: 'MACD', value: 'value' },
            ],
          },
        }
        this.loading = false
      },

      escribir(){
        let hAux = null
        let mAux = null
        let sAux = null

        this.s++;
        if (this.s>59){this.m++;this.s=0;}
        if (this.m>59){this.h++;this.m=0;}

        if (this.s<10){sAux="0"+this.s;}else{sAux=this.s;}
        if (this.m<10){mAux="0"+this.m;}else{mAux=this.m;}

        document.getElementById("hms").innerHTML = mAux + ":" + sAux;
      },

      reiniciarCronometro () {
        document.getElementById("hms").innerHTML="00:00";
        this.m=0;this.s=0;
      }
    },
    created() {
      const token = localStorage.getItem('token')
      if (!token) {
        window.location.href = '/login'
      }
    },
  }
</script>
<style lang="scss">

  .reloj{
    float: left;
    font-size: 40px;
    font-family: Courier,sans-serif;
    color: #363431;
  }

  .modal {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    span.tt {
      margin-right: 25px;
    }
  }
  .table-rsi {
    padding: 20px 0 !important;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
      text-align: center;
      &:nth-child(1) {
        padding-right: 20px;
      }
      &:nth-child(2) {
        padding-left: 20px;
      }
    }
  }
  .companies-error {
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
      li {
        padding-right: 10px;
        font-size: 18px;
        color: #000;
        font-weight: normal;
      }
    }
  }
  .range-field {
    flex: none;
    padding: 0;
    .v-text-field__details {
      display: none !important;
    }
  }
  .gn td {
    color: red !important;
  }
  .strike-column {
    background: #eaebec;
  }
  .v-data-footer__select .v-input {
    display: none !important;
  }
</style>
